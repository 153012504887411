.home {
    background-image:url(./Images/bg.jpg);
    background-size: cover;
    background-position:center;
    background-repeat:no-repeat; 
    display:flex;
    align-items:center;
    justify-content:flex-start;
    height:100vh;
    color:#fff;
    padding:20px;
    position:relative;
}
.home-content {
    position:absolute;
    max-width:600px; 
    left:10vw;
    top:37vh;
}
.home h1 {
    font-size:4em; 
    margin:0 0 10px 0;
}
.home h1 sup {
    font-size:.6em; 
    font-weight:100;
    line-height:.8;
}
.home p {
    margin:0;
    margin-top:20px;
    font-size:1.4em;
    font-weight:300;
    line-height:1;
}
.home p span {
    display:block;
    margin-top:20px;
    font-size:.7em;
    font-weight:100;
    opacity:.6;
}
.home .button {
    display:inline-block;
    padding:10px 25px;
    background:#ff8b0e;
    color:#fff;
    text-decoration:none;
    border:none;
    border-radius:12px;
    margin-top:40px;
    font-size:1.5em;
    transition:background .3s;
}
.home .button:hover {
    background:#ff5355;
    cursor:pointer;
}

@media (max-width: 768px) {
    .home {
        height:50vh;
        display:flex;
        justify-content:center;
        align-items:center;
    }
    .home-content {
        position:static;
        text-align:center;
        max-width:90%;
        font-size:2vw;
        word-wrap:break-word;
        overflow-wrap:break-word;
    }
}
@media (max-width: 450px) {
    .home h1 {
        color:#ff8b0e;
    }
    .home p {
        color:#000;
    }
    .home-content {
        font-size:3vw; 
    }
}

.registration {
    height:calc(100vh - 60px);
    padding:100px;
    text-align:center;
    background:#fff;
}
.registration h2 {
    font-size:3em;
    margin-bottom:40px;
    color:#333;
}
.registration form {
    width:540px;
    margin:0 auto;
    padding:20px;
}
.registration input {
    width:100%;
    padding:10px 15px;
    margin:5px 0;
    border:1px solid #ccc;
    border-radius:8px;
    font-size:1em;
    box-sizing: border-box;
}
.registration input:focus {
    border-color:#007bff;
    outline:none;
}
.registration button {
    width:100%;
    padding:10px 25px;
    background:#01b6bd;
    border-radius:12px;
    border:none;
    cursor:pointer;
    font-size:1.4em;
    margin:20px 0;
    color:#fff;
    transition:background .3s;
}
.registration button:hover {
    background:#0da754;
    cursor:pointer;
}
.registration p {
    font-size:.9em;
    color:#666;
}

@media (max-width: 450px) {
    .registration {
        padding:30px 10px 0;
    }
    .registration form {
        border: none;
        width:340px;
    }
    .registration input {
        width:100%;
    }
}

.footer {
    width:100%;
    font-size:90%;
    text-align:center;
    background: #f2f2f2;;
    color:#000;
    padding:10px;
    margin:0 auto;
    justify-content:space-between;
}
.footer-links {
    display:flex;
    flex-direction:row;
    list-style:none;
    margin:0 auto;
}
.footer-links li {
    margin:10px;
}
.footer-links li:last-child {
    margin-left:auto;
}
.footer-links a {
    color:#000;
    text-decoration:none;
    font-size:1em;
}

@media (max-width: 450px) {
    .footer-links {
       flex-direction:column;
    }
    .footer-links li:last-child {
        margin-left:unset;
    }
}