/* reset */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

html,body,ul,ol,li,dl,dt,dd,p,pre,h1,h2,h3,h4,h5,h6,blockquote,form,fieldset,legend,table,td,th {margin:0;padding:0;}
html,body {width:100%;}
body {background:#fff;color:#000;font:14px/1.5 'Poppins',Arial,'Helvetica Neue',Helvetica,sans-serif;display:flex;flex-direction:column;}
html {scroll-behavior:smooth;}

img {margin:0;border-width:0;padding:0;}
ul {list-style:none;}

* {box-sizing:border-box;outline:none;}
* {touch-action:manipulation;}
* {font-family:'Poppins',sans-serif;}

h1,h2,h3,h4.h5 {letter-spacing:-.03em;line-height:1.032em;font-weight:700;}
h1 {font-size:36px;}
h2 {font-size:30px;font-weight:700;}
h3 {font-size:24px;}
h4 {font-size:18px;font-weight:600;}
h5 {font-size:15px;font-weight:600;}

#root {display:flex;flex-direction:column;}

body {
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
}
